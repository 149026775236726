import { serviceAttributes } from './attributes'
import { init } from './client-lib.js'
import logger, { LOGGER_NAME } from '../logger'

let initializeSplitClientPromise

/**
 * Initializes Split SDK using provided splitKey.
 * If initOptions is passed and contains tenantID, does not use @wf-mfe/auth to determine customer and user.
 * @param {String} splitKey  Split SDK browser key
 * @param [initOptions]  An object with tenantID, userID, attributes and sdkSettings to apply to the Split client
 * @returns {Promise<import("@splitsoftware/splitio").IBrowserClient>}
 */
export function initializeSplitClient(splitKey, initOptions = {}) {
  if (!initializeSplitClientPromise) {
    const { tenantID, userID, attributes, sdkSettings = {} } = initOptions
    const authMFEPromise = tenantID
      ? Promise.resolve()
      : System.import('@wf-mfe/auth')
    initializeSplitClientPromise = Promise.all([
      authMFEPromise,
      System.import('rxjs'),
      System.import('rxjs/operators'),
      // TODO code split out split client NPM package here
    ]).then(([auth, rxjs, operators]) => {
      const customer$ = auth
        ? auth.getCurrentCustomer$()
        : rxjs.of({ ID: tenantID })
      const userId$ = auth
        ? auth.getCurrentUser$().pipe(operators.pluck('ID'))
        : rxjs.of(userID)
      const initClientToggles$ = rxjs.combineLatest(customer$, userId$).pipe(
        operators.mergeMap(([customer, userId]) =>
          rxjs.from(
            init(splitKey, userId, customer.ID, sdkSettings).then((client) => {
              const attrs = {
                ...serviceAttributes,
                ...attributes,
              }
              if (customer?.releaseChannel) {
                attrs.releaseChannel = customer.releaseChannel
              }
              client?.setAttributes(attrs)
              return client
            }),
          ),
        ),
        // share makes this observable stream a multicast stream.
        // This is useful because it allows us to reuse the same stream between all subscribers as long as a single subscriber is active.
        // In this case we care about that so we don't call toggles.init twice (once for the subscription that stays open and once for the subscription that we use to know when init has completed for the first time)
        operators.share(),
      )
      // setup subscription that we never close
      // we need this to remain open so that we get updates to the toggles automatically when the user/customer changes without having to resubscribe.
      // The share operator above is essential to how this works.
      initClientToggles$.subscribe(
        () => {}, // subscription doesn't needs to do anything because the init method sets the values
        (err) => {
          logger.error(
            `${LOGGER_NAME}: Cannot initialize split on client. ${err}`,
          )
        },
      )
      // makes the outer promise to resolve when we've initialized for the first time.
      return initClientToggles$.pipe(operators.first()).toPromise()
    })
  }
  return initializeSplitClientPromise
}

export const __testing_only__ = {
  resetSplitClientPromise: () => (initializeSplitClientPromise = undefined),
}
